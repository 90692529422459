/* eslint-disable consistent-return */
import React, { ClipboardEventHandler, InputHTMLAttributes, MutableRefObject, useState } from "react"
import classNames from "classnames"
import { IMaskMixin } from "react-imask"
import { ReactElement } from "react-imask/dist/mixin"
import tw, { styled } from "twin.macro"

import { When } from "@components/If"

export interface SizeProps {
    size?: "sm" | "md"
}

const Group = tw.div`
    flex flex-col gap-[5px]  
`

const LabelWrapper = tw.div`
    flex items-center justify-between
`

const Label = styled.span<SizeProps>`
    ${tw`title-4 text-main dark:text-dark-main`}
    ${({ size }) => {
        if (size === "sm") return tw`text-[12px]`
    }}
`

const LabelSuffix = tw.div``

const Prefix = tw.div`
    flex mr-1
`

const InputWrapper = tw.div`
    flex gap-3
`

interface WrapperProps extends SizeProps {
    focus?: boolean
    disabled?: boolean
    error?: boolean
    success?: boolean
}

const Wrapper = styled.span<WrapperProps>`
    ${tw`
        flex items-center
        w-full
        border rounded-full
        overflow-hidden
    `}

    ${({ size }) => {
        if (size === "sm") return tw`h-[40px] p-3 text-[12px]`
        return tw`h-[48px] py-3.5 px-6 text-sm`
    }}


    ${({ focus }) => (focus ? tw`border-focus dark:border-dark-focus` : tw`border-main dark:border-dark-main`)}
    ${({ disabled }) =>
        !disabled
            ? tw`xl:hover:border-focus xl:dark:hover:border-dark-focus`
            : tw`bg-background-subtle !border-main dark:bg-dark-background-subtle dark:!border-dark-main`}
    ${({ error, success }) => {
        if (error) return tw`border-error dark:border-dark-error hover:border-error`
        if (success) return tw`border-focus dark:border-dark-focus hover:border-focus`
        return tw``
    }}

    transition: border 0.1s ease;
`

const StyledInput = styled.input<Omit<React.HTMLProps<HTMLInputElement>, "as">>`
    ${tw`text-bold dark:text-dark-bold  bg-transparent !outline-none w-full border-none`}
    flex: 1;
    height: -webkit-fill-available;

    ${({ disabled }) => (disabled ? tw`cursor-not-allowed` : tw`hover:border-primary`)}

    ${tw`focus:!caret-grey-700 dark:focus:!caret-dark-grey-700`}
    &:-webkit-autofill,
    &:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }

    &[data-autocompleted] {
        background-color: transparent !important;
    }
`

const Suffix = tw.div`
    ml-1 flex
`

const Error = styled.span<SizeProps>`
    ${tw`mr-[10px]`}
    ${tw`title-5 text-error dark:text-dark-error`}
    ${({ size }) => {
        if (size === "sm") return tw`text-[10px]`
    }}
`

const HintText = styled.span<SizeProps>`
    ${tw`mr-[10px]`}
    ${tw`title-5 text-additional dark:text-dark-additional`}
    ${({ size }) => {
        if (size === "sm") return tw`text-[10px]`
    }}
`

const Success = styled.span<SizeProps>`
    ${tw`mr-[10px]`}
    ${tw`title-5 text-success dark:text-dark-success`}
    ${({ size }) => {
        if (size === "sm") return tw`text-[10px]`
    }}
`

const Required = styled(Error)`
    ${({ size }) => (size === "md" ? tw`title-4` : tw`title-5`)}

    ${tw`text-error dark:text-dark-error`}
    ${tw`ml-0.5`}
`

const MaskedStyledInput = IMaskMixin(({ inputRef, ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledInput {...(props as InputHTMLAttributes<HTMLInputElement>)} ref={inputRef} />
))

type Value = string
type TypeInput = "text" | "tel" | "number" | "password" | "checkbox" | "radio"

interface MaskedPattern {
    /**
     * The input masking
     */
    mask?: StringConstructor | NumberConstructor | RegExp | String
    /**
     * The thousands separator
     */
    thousandsSeparator?: string
    /**
     * The decimal separator
     */
    radix?: string
    /**
     * Digits after point (for Number Input)
     */
    scale?: number
}

export interface InputProps extends MaskedPattern {
    /**
     * The name for input
     */
    name?: string
    /**
     * The label text displayed before (on the top side of) the input field
     */
    label?: string | React.ReactNode
    /**
     * The label text className
     */
    labelClassName?: string
    /**
     * The suffix icon for the label
     */
    labelSuffix?: React.ReactNode
    /**
     * The prefix icon for the Input
     */
    prefix?: React.ReactNode
    /**
     * The short hint is displayed in the input field before the user enters a value.
     */
    placeholder?: string
    /**
     * The input content value
     */
    value?: string
    /**
     * Callback when user input
     */
    onChange?: (value: Value) => void
    /**
     * Callback when user blur input
     */
    onBlur?: (value: Value) => void
    /**
     * Callback when user paste on input
     */
    onPaste?: ClipboardEventHandler<ReactElement> | undefined
    /**
     * Whether the input is disabled
     */
    disabled?: boolean
    /**
     * The suffix icon for the Input
     */
    suffix?: React.ReactNode
    /**
     * Error message input
     */
    error?: string | boolean
    /**
     * Success input
     */
    success?: string
    /**
     * Reusable blocks for masked patterns
     */
    blocks?: {
        [key: string]: MaskedPattern
    }
    /**
     * The max number (for Number Input)
     */
    max?: number
    /**
     * The min number (for Number Input)
     */
    min?: number
    /**
     * Set the className of wrapper input
     */
    className?: string
    /**
     * Set the panel className of group input
     */
    groupClassName?: string
    /**
     * Set the className of input field
     */
    inputClassName?: string
    /**
     * Set the className of error message
     */
    errorClassName?: string
    /**
     * Set the className of success message
     */
    successClassName?: string
    /**
     * Set the className of prefix
     */
    prefixClassName?: string
    /**
    /**
     * Set the className of suffix
     */
    suffixClassName?: string
    /**
     * Set the className of hint
     */
    hintClassName?: string
    /**
     * Set the required input
     */
    required?: boolean
    /**
     * Set the type input
     */
    type?: TypeInput
    /**
     * Set lazy attribute
     */
    lazy?: boolean
    /**
     * Set input wrapper ref attribute
     */
    inputWrapperRef?: MutableRefObject<HTMLInputElement>
    /**
     * Set inputref attribute
     */
    inputRef?: MutableRefObject<HTMLInputElement>
    /**
     * Set autocomplete attribute
     */
    autoComplete?: string
    /**
     * Set autofocus attribute
     */
    autoFocus?: boolean
    /**
     * Add component at Input right side.
     */
    inputRightSide?: React.ReactNode
    /**
     * only change on focus
     */
    onlyChangeOnFocus?: boolean
    /**
     * size input
     */
    size?: "sm" | "md"
    /**
     * Show hint text
     */
    hint?: React.ReactNode | string
    /**
     * readOnly for select component
     */
    isReadOnly?: boolean
    /**
     * Callback when user input
     */
    onFocus?: () => void
}

const Input: React.FC<InputProps> = ({
    name,
    label,
    labelClassName,
    labelSuffix,
    prefix,
    placeholder,
    value,
    onChange,
    onBlur,
    onPaste,
    disabled,
    suffix,
    error,
    success,
    mask = String,
    blocks,
    thousandsSeparator,
    radix,
    scale,
    max,
    min,
    className,
    groupClassName,
    inputClassName,
    errorClassName,
    successClassName,
    prefixClassName,
    suffixClassName,
    required,
    type,
    lazy,
    inputWrapperRef,
    inputRef,
    autoComplete,
    autoFocus,
    inputRightSide,
    onlyChangeOnFocus,
    size,
    hint,
    hintClassName,
    isReadOnly,
    onFocus
}: InputProps) => {
    const [focus, setFocus] = useState(false)

    const handleChange = (newValue: string) => {
        if (!onlyChangeOnFocus) {
            onChange?.(newValue)
            return
        }

        if (focus) {
            onChange?.(newValue)
        }
    }

    const handleFocus = () => {
        setFocus(true)
    }

    const handleBlur = () => {
        onBlur?.(value as Value)
        setFocus(false)
    }

    return (
        <Group className={classNames("reku-new", groupClassName)}>
            <When condition={label}>
                <LabelWrapper>
                    <Label className={labelClassName} size={size}>
                        {label}
                        <When condition={required}>
                            <Required>*</Required>
                        </When>
                    </Label>
                    <When condition={labelSuffix}>
                        <LabelSuffix>{labelSuffix}</LabelSuffix>
                    </When>
                </LabelWrapper>
            </When>
            <InputWrapper ref={inputWrapperRef}>
                <Wrapper
                    size={size}
                    focus={focus}
                    disabled={disabled}
                    className={className}
                    error={!!error}
                    success={!!success}
                >
                    <When condition={prefix}>
                        <Prefix className={classNames("reku-input-prefix", prefixClassName)}>{prefix}</Prefix>
                    </When>
                    <MaskedStyledInput
                        data-testid='reku-input'
                        readOnly={isReadOnly}
                        name={name}
                        value={value}
                        placeholder={placeholder}
                        onAccept={handleChange}
                        onFocus={onFocus || handleFocus}
                        onBlur={handleBlur}
                        disabled={disabled}
                        mask={mask as NumberConstructor}
                        blocks={blocks as any}
                        unmask={true as false}
                        thousandsSeparator={thousandsSeparator}
                        radix={radix}
                        scale={scale}
                        max={max}
                        min={min}
                        type={type}
                        lazy={lazy}
                        onPaste={onPaste}
                        inputRef={(referrence: HTMLInputElement) => {
                            if (inputRef) {
                                // eslint-disable-next-line no-param-reassign
                                inputRef.current = referrence
                            }
                        }}
                        className={inputClassName}
                        autoComplete={autoComplete}
                        autoFocus={autoFocus}
                    />
                    <When condition={suffix}>
                        <Suffix className={classNames("reku-input-suffix", suffixClassName)}>{suffix}</Suffix>
                    </When>
                </Wrapper>
                {inputRightSide}
            </InputWrapper>
            <When condition={hint}>
                <HintText className={hintClassName}>{hint}</HintText>
            </When>
            <When condition={error}>
                <Error className={errorClassName}>{error}</Error>
            </When>
            <When condition={success}>
                <Success className={successClassName}>{success}</Success>
            </When>
        </Group>
    )
}

Input.defaultProps = {
    name: undefined,
    value: undefined,
    onChange: undefined,
    onBlur: undefined,
    onPaste: undefined,
    label: undefined,
    labelClassName: undefined,
    labelSuffix: undefined,
    placeholder: undefined,
    prefix: undefined,
    disabled: false,
    suffix: undefined,
    error: undefined,
    success: undefined,
    mask: String,
    blocks: undefined,
    thousandsSeparator: ".",
    radix: ",",
    scale: undefined,
    max: undefined,
    min: undefined,
    className: undefined,
    groupClassName: undefined,
    inputClassName: undefined,
    errorClassName: undefined,
    successClassName: undefined,
    prefixClassName: undefined,
    suffixClassName: undefined,
    hintClassName: undefined,
    required: false,
    type: undefined,
    lazy: true,
    inputRef: undefined,
    autoComplete: undefined,
    autoFocus: false,
    onlyChangeOnFocus: false,
    size: "sm",
    hint: undefined,
    isReadOnly: false,
    onFocus: undefined
}

export default Input
